<template>
  <switchIdentity :list="memberProviders" :selected="selectedProvider" title="請選擇要管理的供應商" register_btn="申請新供應商"
    @switchIdentity="clickSwitchIdentity" @register="clickRegisterProvider"></switchIdentity>
</template>

<script lang="babel" type="text/babel">
import { mapGetters } from 'vuex'

export default {
  components: {
    switchIdentity: () => import('@/components/switchIdentity/switchIdentity.vue')
  },
  data: ()=>({
    
  }),
  computed: {
    ...mapGetters('member', ['currMemberRole']),
    selectedProvider() {
      return this.$store.getters[`member/selectedProvider`]
    },
    memberProviders() {
      const memberProviders = this.$store.getters[`member/memberProviders`]
      return memberProviders.map((item) => {
        item.id = item.provider_id
        item.name = item.provider_name
        return item
      })
    },
  },
  methods: {
    async readProvider(providerId) {
      this.$store.dispatch('loading/active')
      try {
        const res = await this.$api.collection.providerApi.read(providerId)
        if(!res.line_at_id) throw 'no member'
        this.$store.dispatch('base/setLayoutProvider', res)
      } catch(err) {
        console.log(err)
        if(err === 'no member') this.$apopup.base({ title: '此供應商申請中。', closeLabel: '確定' })
        throw new Error(err)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async clickSwitchIdentity(item) {
      this.$root.gaLogEvent('供應商_點擊_切換供應商')
      try {
        const res = await this.readProvider(item.id)
        this.$store.dispatch(`member/switchSelectedProvider`, item.id)
      } catch(err) {
        console.log(err)
      }
    },
    clickRegisterProvider() {
      this.$router.push({
        name: 'provider-register',
        query: { forceCreate: true }
      });
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
